.footer-container {
  text-align: center;
  padding: 10px;

  h1 {
    font-size: 1.25em;

    a {
      color: #dc3545;
    }
  }
}
