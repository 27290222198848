.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 2em;

  h1 {
    font-size: 2em;
  }

  button {
    font-weight: 700;
    font-size: 1.2em;
    border-radius: 30px;
    padding: 0.5em 1.2em;
  }
}
