.generator-container {
  // border: 1px solid blue;
  margin: auto;
  font-size: 2em;
  margin-top: -1em;

  .generate-btn {
    margin: auto;
    text-align: center;
    margin-top: 1em;
    margin-bottom: -3em;
  }
}
